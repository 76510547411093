import * as React from "react";
import Config from '../app_config.json';
import {Component} from "react";
import {BrowserRouter as Router, Route, Routes, useParams} from "react-router-dom";
import DocsWrapper from "./DocsWrapper";
import '../App.css'
import Home from "./Home";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            organizationConfig: null,
            definitionList: null,
        }
        this.getApiData = this.getApiData.bind(this)
        this.getOrganizationData = this.getOrganizationData.bind(this)
        this.getCurrentApiLink = this.getCurrentApiLink.bind(this)
    }

    componentWillMount() {
        let apisList = this.getOrganizationData(Config.orgData.orgName)

        this.setState({
            organizationConfig:  Config.orgData,
            definitionList: apisList
        })
    }

    getApiData(inputMethod, inputResource) {
        let url = Config.orgData.apiBaseUrl + inputResource + '.json';

        return fetch(url, {
            method: inputMethod
        }).then(response => {
            if (response.ok) {
                return response.json()
            } throw new Error('There was an issue requesting the API')
        }).then(json => {
            return json
        })
    }

    getOrganizationData(resourceName) {

        this.getApiData('GET', resourceName).then(response => {
            this.setState({
                definitionList: response.apis
            })
        })
    }

    getCurrentApiLink($slug) {
        if ( undefined !== this.state.definitionList && null !== this.state.definitionList ) {
            for (var i = 0; i < this.state.definitionList.length; i++) {

                if ( 'true' === this.state.definitionList[i].show
                    && $slug == this.state.definitionList[i].slug ) {
                    return this.state.definitionList[i].url;
                }
            }
        }
        return '';
    }

    render() {

        let routesList = []

        routesList.push(<Route key="home" path="/" element={<Home
        organizationConfig={this.state.organizationConfig}
        definitionList={this.state.definitionList}
        />} />)

        if ( undefined !== this.state.definitionList && null !== this.state.definitionList ) {
            this.state.definitionList.map(definionItem => {
                if ( 'true' === definionItem.show ) {
                    routesList.push(<Route key="{definionItem.slug}" path="/:slug" element={<DocsWrapper
                    organizationConfig={this.state.organizationConfig}
                    definitionList={this.state.definitionList}
                    getCurrentApiLink={this.getCurrentApiLink}
                    />} />)
                }
            })
        }

        return (
                <Router>
                    <Routes>
                      {routesList}
                    </Routes>
                </Router>
        );
    }

}

export default App;
