import React from 'react';
import {
    Link, useParams
} from "react-router-dom";


const Sidebar = props => {

    const { slug } = useParams();

    let organizationConfig = props.organizationConfig
    let apiLinks = []

    if ( undefined !== props.definitionList ) {
        props.definitionList.map(definionItem => {
            if ( 'true' == definionItem.show ) {
            apiLinks.push(<li key={definionItem.slug} className={(slug === definionItem.slug) ? 'active' : ''}>
                <Link to={`/${definionItem.slug}`}>{definionItem.name}</Link>
            </li>)
            }
        })
    }

    return (
        <div className="side-bar">
            <div className="side-bar-header">
                <h1>{organizationConfig.displayName}</h1>
                <h3>API DOCS</h3>
            </div>
            <div className="side-bar-body">
                <nav>
                    <ul>
                        {apiLinks}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Sidebar;
