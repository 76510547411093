import React from 'react';
import Sidebar from './Sidebar.js'
import Loader from "./Loader";
import SwaggerUI from "swagger-ui-react";
import '../../node_modules/swagger-ui-react/swagger-ui.css'
import {useParams} from "react-router";

function DocsWrapper(props) {

    const { slug } = useParams();

    if ( undefined == props.definitionList || null == props.definitionList ) {
        return (<Loader/>);
    }

    let apiLink = props.getCurrentApiLink(slug)

    return (
        <div className="App">
            <Sidebar organizationConfig={props.organizationConfig}
            definitionList={props.definitionList} />
                {apiLink &&
                <SwaggerUI url={apiLink} />
                }
        </div>
);
};


export default DocsWrapper;
