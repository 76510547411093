import React from 'react';
import Sidebar from './Sidebar.js'
import Loader from "./Loader";
import SwaggerUI from "swagger-ui-react";
import '../../node_modules/swagger-ui-react/swagger-ui.css'
import {useParams} from "react-router";

function Home(props) {

    if ( undefined == props.definitionList || null == props.definitionList ) {
        return (<Loader/>);
    }

    return (
        <div className="App">
            <Sidebar organizationConfig={props.organizationConfig}
        definitionList={props.definitionList} />
        <div className="swagger-ui">
            <div>
                <div className="information-container wrapper">
                    <section className="block col-12">
                        <div>
                            <div className="info">
                                <hgroup className="main">
                                    <h2 className="title">Select your api</h2>
                                </hgroup>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Home;
