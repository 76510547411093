import React from 'react';

const Loader = props => {

    return (
        <div>
            <h2>Loading</h2>
        </div>
)
}

export default Loader;
